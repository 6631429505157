import _ from "lodash";
import { PricingRequest } from "../components/OverviewStep";
import {
	PricingRequestAPI,
	Granularity,
} from "../pricingRequestStepper.schema";
import { format } from "date-fns";
import { Portfolio, Site, isPortfolio } from "../../sites/sites.module";
import { Tender } from "../../tender_page/tender.module";
import { notNullLike } from "../../../common/utils/notNullLike";
import {
	PricingRequestData,
	PricingRequestDataKeys,
} from "../pricingRequestStepper.module";
import { CommodityFixings } from "../PricingRequestStepper.slice";

const granularity: Granularity[] = ["MONTH", "QUARTER", "CAL", "WHOLE_HORIZON"];

function getGranularitiesBasedOnFlags(
	pricing: PricingRequestData
): Granularity[] {
	return granularity.filter(function (elem: string, index: number) {
		const flags: Boolean[] = [
			pricing.granularityMonthly,
			pricing.granularityQuarter,
			pricing.granularityCal,
			pricing.granularityWholeHorizon,
		];
		return flags[index];
	});
}

export function getPortfoliosFromPricingRequestForm(
	sitesValues: (Portfolio | Site)[]
) {
	return sitesValues.filter(
		(portfolio: Portfolio | Site) => "sites" in portfolio && portfolio.sites
	);
}

export function getSitesFromPricingRequestForm(
	sitesValues: (Portfolio | Site)[]
) {
	return sitesValues.filter((site: Portfolio | Site) => !("sites" in site));
}

export function transformPricingRequestDataToAdaptApi(
	pricingRequest: PricingRequest,
	tenderId: number,
	isFinancial: boolean = false
): PricingRequestAPI {
	const pricing = pricingRequest.pricingValues;
	const sites = pricingRequest.sitesValues;
	const mapped: PricingRequestAPI = {
		start_date: format(pricing.startDate, "yyyy-MM-dd"),
		end_date: format(pricing.endDate, "yyyy-MM-dd"),
		alpha: pricing.alpha,
		beta: pricing.beta,
		alpha_and_beta: pricing.alphaAndBeta,
		pricing_date: pricing.live
			? undefined
			: pricing.pricingDate
			? format(pricing.pricingDate, "yyyy-MM-dd")
			: undefined,
		is_real_time: pricing.live,
		granularities: getGranularitiesBasedOnFlags(pricing),
		clickability: pricing.clickOptionSwitch
			? pricing.clickGranularity.map((item) => {
					const clickKey =
						`clickCount${item}` as PricingRequestDataKeys;
					return {
						granularity: item,
						click_count: Number(pricing[clickKey]),
					};
			  })
			: undefined,
		tender_id: tenderId,
		site_ids: getSitesFromPricingRequestForm(sites).map((el) => el.id),
		portfolio_ids: getPortfoliosFromPricingRequestForm(sites).map(
			(el) => el.id
		),
		types: _.isEmpty(pricing.pricingType)
			? [pricing.strikePricingType as CommodityFixings]
			: pricing.pricingType,
		base_capacity_volume_percentage: Number(pricing.baseCapacity) / 100,
		chapter51_type: pricing.chapter51Switch
			? pricing.chapter51Type
			: undefined,
		chapter51_number_of_hours: pricing.chapter51Switch
			? pricing.chapter51NumberOfHours
			: undefined,

		ppa_with_goo: pricing.ppaWithGooSwitch,
		goo_power_adjustment: Number(pricing.gooPowerAdjustment) / 100,
		// TODO for now it is always false
		only_goo: false,
		negative_price_value: pricing.hasNegativePrice
			? pricing.negativePriceValue
			: null,

		is_financial: isFinancial,
		commodity_pricing_type: pricing.commodityPricingType,
		strike_pricing_type: pricing.strikePricingType,
		average_type: pricing.averageType,

		commodity_beta: pricing.commodityBeta,
		commodity_alpha: pricing.commodityAlpha,
		commodity_has_negative_price: pricing.commodityHasNegativePrice,
		strike_has_negative_price: pricing.strikeHasNegativePrice,
		has_base_options: pricing.hasBaseOptions,
		leg_type: pricing.legType,
	};

	return mapped;
}

const deepCopyAndSortPortfolioChildren = (portfolio: Portfolio): Portfolio => {
	const childrenSite = _.cloneDeep(portfolio.children);
	const sortedChildren = _.sortBy(childrenSite, (site) => site.name);

	// changing children directly will trigger an error Cannot Assign to Read Only
	return { ...portfolio, children: sortedChildren };
};

export function mergeSitesAndPortfolios(tender?: Tender) {
	if (!tender) {
		return [];
	}
	const portfolios = (tender.portfolios || [])
		.map((portfolio) => ({
			...portfolio,
			children: portfolio.sites || [],
		}))
		.filter(notNullLike)
		.filter((portfolio) => !portfolio.is_portfolio_all);
	return [...portfolios, ...(tender?.sites || [])];
}

export const sortSitesOrPortfoliosByNameAscending = (
	sitesOrPortfolios: (Site | Portfolio)[]
) => {
	const sortedSiteOrPortfolio = _.sortBy(
		sitesOrPortfolios,
		(siteOrPortoflio) => siteOrPortoflio.name
	);
	const [portfolios, sites] = _.partition(
		sortedSiteOrPortfolio,
		(siteOrPortfolio: Site | Portfolio) => isPortfolio(siteOrPortfolio)
	);
	const sortedPortfolio = portfolios.map((portfolio) =>
		deepCopyAndSortPortfolioChildren(portfolio as Portfolio)
	);
	// @ts-ignore
	return sortedPortfolio.concat(sites);
};

export const resetDateHours = (date: Date) =>
	date ? new Date(date.setHours(0, 0, 0, 0)) : date;
