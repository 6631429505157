export function shouldDiplayAlphAndBeta(
	pricingOption: string,
	fieldName?: string
) {
	if (
		fieldName &&
		[
			"BetaContract",
			"AlphaForAlphaAndBeta",
			"BetaForAlphaAndBeta",
		].includes(fieldName) &&
		pricingOption === "alpha"
	) {
		return "none";
	}
	if (
		fieldName &&
		["AlphaIndex", "AlphaForAlphaAndBeta", "BetaForAlphaAndBeta"].includes(
			fieldName
		) &&
		pricingOption === "beta"
	) {
		return "none";
	}
	if (
		fieldName &&
		["BetaContract", "AlphaIndex"].includes(fieldName) &&
		pricingOption === "alpha_and_beta"
	) {
		return "none";
	}
	if (
		fieldName &&
		[
			"AlphaIndex",
			"BetaContract",
			"AlphaForAlphaAndBeta",
			"BetaForAlphaAndBeta",
		].includes(fieldName) &&
		!pricingOption
	) {
		return "none";
	}
	return "flex";
}
