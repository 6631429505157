import InputAdornment from "@mui/material/InputAdornment";
import CustomInput from "../../../../common/components/CustomInput";
import { ChangeEvent, useCallback, useMemo, useRef } from "react";
import {
	Action,
	Field,
	FieldGroup,
	Party,
	Pricing,
	setPricingOptions,
	setPricingTso,
	updatePartiesLoader,
} from "../../pricingListSlice";
import PricingModal from "../PricingModal";
import { Formik, FormikErrors, FormikProps, FormikValues } from "formik";
import { useActionFieldValidation } from "../../../../common/hooks/useActionFieldValidation";
import CustomAutocomplete from "../../../../common/components/CustomAutocomplete";
import { getParties } from "../../pricingList.thunk";
import { useAppDispatch } from "../../../../common/hooks/default";
import { useSelector } from "react-redux";
import {
	selectParties,
	selectPartiesLoader,
	selectPricingOptionsDefaultValue,
	selectPricingOptionsLoader,
	selectTsoDefaultValue,
	selectTsoLoader,
	selectTsoWarning,
} from "../../pricingList.selector";
import { ValidationError } from "../../../../common/hooks/usePydanticErrorsFormat";
import {
	Alert,
	Box,
	FormLabel,
	IconButton,
	List,
	ListItem,
	ListItemText,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import * as _ from "lodash";
import { useDeepMemo } from "../../../../common/hooks/useDeepMemo";
import { selectTender } from "../../../tender_form/tenderForm.selector";
import { If } from "../../../../common/components/If";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import AddIcon from "@mui/icons-material/Add";
import { isString } from "../../../../common/utils/isString";
import RadioButtonField from "./RadioButtonfield";
import { CustomToggleButtonGroup } from "../../../../common/components/ToggleButtonGroup/ToggleButtonGroup";
import { shouldDiplayAlphAndBeta } from "./utils";

interface ActionPricingModalProps {
	open: boolean;
	onClose: () => void;
	forPricings: Pricing[];
	onSubmit: (body: Record<string, string | number | boolean>) => void;
	isLoading: boolean;
	action?: Action | null;
	errorMessage: string | ValidationError | undefined | null;
	hasMorePricings?: boolean;
}

export function mapParty(party: Party | undefined) {
	if (party)
		return {
			id: party.party_id,
			partyId: party.party_id,
			name: party.name,
			shortName: party.short_name,
		};
}

function formatFormikError(
	error:
		| string
		| string[]
		| null
		| undefined
		| FormikErrors<any>
		| FormikErrors<any>[]
): string | undefined {
	if (!error) {
		return undefined;
	}
	if (Array.isArray(error)) {
		if (!error.length) {
			return undefined;
		}
		if (typeof error[0] === "string") {
			return error.join(", ");
		}
		return undefined;
	}
	if (typeof error === "string") {
		return error;
	}
	return "";
}

export default function ActionPricingModal(props: ActionPricingModalProps) {
	const {
		open,
		isLoading,
		forPricings,
		action,
		errorMessage,
		onClose,
		onSubmit,
	} = props;

	const formRef = useRef<FormikProps<FormikValues>>();
	const defaultTso = useSelector(selectTsoDefaultValue);
	const defaultPricingOptions = useSelector(selectPricingOptionsDefaultValue);
	const tsoLoader = useSelector(selectTsoLoader);
	const pricingOptionsLoader = useSelector(selectPricingOptionsLoader);
	const tsoWarning = useSelector(selectTsoWarning);
	const actionFields = action?.fields || {};

	// useDeepMemo instead of use memo because an incoming message would change
	// the reference to the action, but when compared deep it does not changes
	// so we actually don't want it to re-render
	const fields = useDeepMemo(() => {
		if (action) {
			const newFields: Record<string, any> = {};
			_.toPairs(actionFields).forEach(([fieldName, field]) => {
				if (field.is_array) {
					const arrayFieldName = fieldName + "_array";
					const arrayField = { ...field, label: arrayFieldName };
					newFields[arrayFieldName] = arrayField;
					newFields[fieldName] = {
						...field,
						is_array: false,
						required: false,
					};
				} else {
					newFields[fieldName] = field;
				}
			});
			return newFields;
		}
		return {};
	}, [action]);

	function prepareValuesFromActionFields(fieldsToPrepare: {
		[key: string]: Field | FieldGroup;
	}) {
		const values: Record<string, any> = {};
		_.toPairs(fieldsToPrepare).forEach(([fieldName, field]) => {
			if (field.is_array) {
				const arrayFieldName = fieldName + "_array";
				values[arrayFieldName] = [];
			} else if (field.name == "tso") {
				values[fieldName] = defaultTso;
			} else if (field.name == "pricingOptions") {
				values[fieldName] = defaultPricingOptions;
			} else {
				if (Array.isArray(field.label)) {
					values[fieldName + "_label"] = field.default_label;
				}
				// try to map fieldname with a field from summary in order to prefill
				let prefillValues: any = undefined;
				if (field.prefill) {
					prefillValues = forPricings.map((pricing) => {
						if (Array.isArray(field.prefill!)) {
							return _.get(
								pricing,
								field.prefill.find((x) => {
									return !!_.get(pricing, x);
								})
							);
						} else {
							return _.get(pricing, field.prefill!);
						}
					});

					prefillValues = prefillValues.filter(
						(element: number) => element !== null
					);
					if (field.default_label == "%" && prefillValues[0]) {
						prefillValues[0] = prefillValues[0] * 100;
					}
				}
				values[fieldName] = prefillValues
					? prefillValues[0]
					: undefined;
			}
		});

		return values;
	}

	// useDeepMemo instead of use memo because an incoming message would change
	// the reference to the action, but when compared deep it does not changes
	// so we actually don't want it to re-render
	const initialValues = useDeepMemo(() => {
		if (!action) {
			return {};
		}

		if (action?.fields?.party) {
			return {
				party: mapParty({
					id: forPricings[0].party?.party_id,
					name: forPricings[0].party?.name || "",
					party_id: forPricings[0].party?.party_id || 0,
					short_name: forPricings[0].party?.short_name || "",
				}),
				mirror_book: forPricings[0]?.mirror_book,
			};
		}

		/**
		 * For array fields we alter the values we pass to formik.
		 * For regular fields we should end with:
		 * {
		 * 	fieldA: undefined,
		 *  fieldB: undefined
		 * }
		 *
		 * But for arrays we want:
		 * {
		 *   fieldA_array: [],
		 * }
		 *
		 */
		return prepareValuesFromActionFields(actionFields);
	}, [action, fields, forPricings, defaultTso]);

	const conflictingFields = useDeepMemo(() => {
		if (action) {
			const conflicting: Record<string, boolean> = {};
			_.toPairs(actionFields).forEach(([fieldName, field]) => {
				if (!field.is_array) {
					conflicting[fieldName] = false;
					if (field.prefill) {
						let values = forPricings.map((pricing) => {
							if (Array.isArray(field.prefill!)) {
								return _.get(
									pricing,
									field.prefill.find((x) => {
										return !!_.get(pricing, x);
									})
								);
							} else {
								return _.get(pricing, field.prefill!);
							}
						});
						values = values.filter((element) => element !== null);

						conflicting[fieldName] = values.reduce(
							(acc, curr) => acc || curr !== values[0],
							false
						);
					}
				}
			});
			return conflicting;
		}
		return {};
	}, [action, forPricings]);

	const disabledFields = useMemo(() => {
		const disabled: Record<string, boolean> = {};
		if (action) {
			_.toPairs(actionFields).forEach(([fieldName, field]) => {
				if (!field.is_array) {
					disabled[fieldName] = false;
					if (field.disabled) {
						if (isString(field.disabled)) {
							disabled[fieldName] = forPricings.every(
								(pricing) => {
									const disablePath =
										field.disabled as string;
									if (disablePath[0] == "!") {
										return !_.get(
											pricing,
											disablePath.slice(1)
										);
									}
									return _.get(pricing, disablePath);
								}
							);
						} else {
							disabled[fieldName] = !!field.disabled;
						}
					}
				}
			});
		}
		return disabled;
	}, [action, forPricings]);

	let validationSchema = useActionFieldValidation(
		fields || {},
		1,
		disabledFields
	);
	const dispatch = useAppDispatch();
	const partiesLoader = useSelector(selectPartiesLoader);
	const parties = useSelector(selectParties);

	const fetchParties = (search: string) => {
		dispatch(updatePartiesLoader(true));
		dispatch(getParties(search));
	};
	const tender = useSelector(selectTender);
	const loaderDataAutoComplete = useMemo<boolean>(() => {
		return partiesLoader as boolean;
	}, [partiesLoader]);

	const fetchDataAutoComplete = useCallback(
		(fieldName: string) => {
			if (fieldName == "party") return parties;
			return [];
		},
		[parties, forPricings, tender]
	);

	const triggerAutocompleteDataFetch = (
		fieldName: string,
		search: string = ""
	): void => {
		if (fieldName == "party") {
			fetchParties(search);
		}
	};
	const customSetFieldValue = (
		setFieldValue: (
			field: string,
			value: any,
			shouldValidate?: boolean
		) => void,
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (e.target.name == "baseloadPrice" && action?.action === "SET_BETA") {
			const baseLoadPrice = parseFloat(e.target.value);
			const alphaClient =
				forPricings[0]?.pricing_runs[0].summary?.AlphaClient?.value ||
				0;

			const beta = alphaClient * baseLoadPrice - baseLoadPrice;
			setFieldValue("beta", beta);
		}
	};

	const getLoaderForRadioButton = useCallback(
		(fieldName: string, defaultLoader: boolean) => {
			if (action?.action && fieldName === "tso") {
				return tsoLoader || defaultLoader;
			}
			if (action?.action && fieldName === "pricingOptions") {
				return pricingOptionsLoader || defaultLoader;
			}
			return defaultLoader;
		},
		[action?.action, forPricings, tsoLoader, pricingOptionsLoader]
	);

	const getDefaultValue = useCallback(
		(value: string, fieldName: string) => {
			if (action?.action && fieldName === "tso" && defaultTso) {
				return value || defaultTso;
			}
			if (
				action?.action &&
				fieldName === "pricingOptions" &&
				defaultPricingOptions
			) {
				return value || defaultPricingOptions;
			}
			return value || "";
		},
		[action?.action, defaultTso, defaultPricingOptions]
	);

	const cleanRadioGroupDefaultValue = () => {
		dispatch(setPricingTso({ defaultValue: undefined, options: [] }));
		dispatch(setPricingOptions({ defaultValue: undefined, options: [] }));
	};

	const onModalClose = () => {
		cleanRadioGroupDefaultValue();
		onClose();
	};
	return (
		<PricingModal
			open={open}
			onClose={onModalClose}
			onSubmit={() => {
				if (formRef.current) {
					cleanRadioGroupDefaultValue();
					// @ts-ignore
					formRef.current.submitForm();
				}
			}}
			forPricings={forPricings}
			title={action?.display_name || ""}
			subTitle={
				props.hasMorePricings
					? `Applies to all possible pricings on the tender pricings, including these as example:`
					: "Applicable for the following pricings:"
			}
			warningMessage={tsoWarning}
			isSubmitDisabled={isLoading}
			isLoading={isLoading}
			errorMessage={errorMessage}
		>
			<Formik
				key={"formik-action-pricing-modal"}
				//@ts-ignore
				innerRef={formRef}
				initialValues={initialValues}
				validationSchema={validationSchema}
				enableReinitialize={true}
				onSubmit={async (values) => {
					onSubmit(values);
				}}
			>
				{({
					handleChange,
					handleSubmit,
					values,
					errors,
					setFieldValue,
					touched,
				}) => {
					return (
						<form
							noValidate
							key={"form-action-pricing-modal"}
							onSubmit={handleSubmit}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: "5px",
								}}
							>
								{Object.entries(actionFields).map(
									(value, index) => (
										<Box
											key={`${value[0]}_container`}
											sx={{
												display:
													shouldDiplayAlphAndBeta(
														values.pricingOptions,
														value[1].name
													),
												flexDirection: "column",
											}}
										>
											<Box
												sx={{
													display: "flex",
													flexDirection: "row",
													gap: "8px",
													alignItems: "center",
												}}
											>
												{value[1]?.display_name
													?.length && (
													<FormLabel
														sx={{
															width: "250px",
															wordBreak:
																"break-all",
														}}
													>
														{value[1]?.display_name}
													</FormLabel>
												)}
												<Box
													sx={{
														display: "flex",
														alignItems: "center",
														flex: 1,
													}}
												>
													{value[1].component ==
														"radiogroup" && (
														<RadioButtonField
															loader={getLoaderForRadioButton(
																value[0],
																isLoading
															)}
															field={
																value[1] as Field
															}
															onChange={(
																event: React.ChangeEvent<HTMLInputElement>
															) => {
																setFieldValue(
																	value[1]
																		.label as string,
																	event.target
																		.value
																);
															}}
															errors={formatFormikError(
																errors[
																	(value[1]
																		.label as string) ||
																		""
																]
															)}
															pricingId={
																forPricings[0]
																	?.id
															}
															selectedValue={getDefaultValue(
																values[
																	value[1]
																		.label as string
																],
																value[0]
															)}
														/>
													)}
													{value[1].component ===
														"input" && (
														<>
															<CustomInput
																data-testid={
																	"action-field-" +
																	value[0]
																}
																key={
																	value[1]
																		.title +
																	"input"
																}
																InputProps={{
																	endAdornment:
																		(
																			<InputAdornment position="start">
																				{!Array.isArray(
																					value[1]
																						.label
																				) &&
																					value[1]
																						.label}
																			</InputAdornment>
																		),
																	type: value[1]
																		.type,
																}}
																rows={3}
																name={value[0]}
																autoFocus={
																	index === 0
																}
																placeholder={
																	values[
																		value[0]
																	] ||
																	value[1]
																		.title
																}
																disabled={
																	disabledFields[
																		value[0]
																	]
																}
																fullWidth
																onChange={async (
																	e
																) => {
																	await handleChange(
																		e
																	);
																	customSetFieldValue(
																		setFieldValue,
																		e
																	);
																}}
																variant="standard"
																// @ts-ignore
																value={
																	values[
																		value[0]
																	]
																}
																// @ts-ignore
																errorText={
																	errors[
																		value[0]
																	]
																}
																touched={true}
															/>
															{Array.isArray(
																value[1].label
															) && (
																<CustomToggleButtonGroup
																	sx={{
																		height: "40px",
																	}}
																	groupValue={
																		values[
																			value[0] +
																				"_label"
																		]
																	}
																	onValueChange={(
																		choice
																	) => {
																		setFieldValue(
																			value[0] +
																				"_label",
																			choice
																		);
																	}}
																	groupLabel="all-or-active-only"
																	buttonValues={
																		value[1]
																			.label
																	}
																	displayValues={
																		value[1]
																			.label
																	}
																/>
															)}
														</>
													)}

													{value[1].component ==
														"autocomplete" && (
														<CustomAutocomplete
															key={
																value[0] +
																"autocomplete"
															}
															sx={{
																"& .MuiInputLabel-shrink":
																	{
																		display:
																			"none",
																	},
																width: "100%",
																marginBottom: 0,
															}}
															fetchData={(
																search
															) =>
																triggerAutocompleteDataFetch(
																	value[0],
																	search
																)
															}
															data={fetchDataAutoComplete(
																value[0]
															)}
															label={
																value[1]
																	.label as string
															}
															name={value[0]}
															loading={
																loaderDataAutoComplete
															}
															setFieldValue={
																setFieldValue
															}
															renderOption={
																value[0] ===
																"proxy_refs"
																	? (
																			optionProps,
																			option,
																			state
																	  ) => {
																			if (
																				option ===
																				state.inputValue
																			) {
																				return (
																					<li
																						{...optionProps}
																						key={
																							option
																						}
																					>
																						<AddIcon />
																						<span
																							style={{
																								marginLeft: 8,
																							}}
																						>
																							{
																								option
																							}
																						</span>
																					</li>
																				);
																			}
																			return (
																				<li
																					{...optionProps}
																					key={
																						option.id
																					}
																				>
																					<If
																						condition={
																							option.mapped
																						}
																					>
																						<LinkIcon />
																					</If>
																					<If
																						condition={
																							!option.mapped
																						}
																					>
																						<LinkOffIcon />
																					</If>
																					<span
																						style={{
																							marginLeft: 8,
																						}}
																					>
																						{
																							option.name
																						}
																					</span>
																				</li>
																			);
																	  }
																	: undefined
															}
															value={
																values[value[0]]
															}
															onChange={
																handleChange
															}
															freeSolo={
																value[0] ==
																"proxy_refs"
															}
															error={
																!!errors[
																	value[0]
																] &&
																!!touched[
																	value[0]
																]
															}
															helperText={
																!!errors[
																	value[0]
																] &&
																touched[
																	value[0]
																]
																	? (
																			errors[
																				value[0]
																			] as any
																	  ).name
																	: ""
															}
														/>
													)}
													{value[1].is_array && (
														<>
															<IconButton
																disabled={
																	!values[
																		value[0]
																	]
																}
																type="button"
																color="primary"
																onClick={() => {
																	const fieldValues =
																		values[
																			`${value[0]}_array`
																		];
																	fieldValues.push(
																		values[
																			value[0]
																		]
																	);
																	setFieldValue(
																		`${value[0]}_array`,
																		fieldValues
																	);
																	setFieldValue(
																		`${value[0]}`,
																		""
																	);
																}}
															>
																<AddCircleIcon />
															</IconButton>
														</>
													)}
												</Box>
												{value[1].is_array && (
													<>
														{errors[
															`${value[0]}_array`
														] &&
															touched[
																`${value[0]}_array`
															] && (
																<p
																	style={{
																		color: "red",
																	}}
																>
																	-{" "}
																	{
																		errors[
																			`${value[0]}_array`
																		] as string
																	}
																</p>
															)}
														<List
															dense={false}
															sx={{
																width: "100%",
															}}
														>
															{!!values[
																`${value[0]}_array`
															] &&
																values[
																	`${value[0]}_array`
																].map(
																	(
																		item:
																			| string
																			| {
																					id: number;
																					name: string;
																			  }
																	) => (
																		<ListItem
																			key={`${value[0]}_array`}
																			secondaryAction={
																				<IconButton
																					sx={{
																						marginRight:
																							"-15px",
																					}}
																					edge="end"
																					aria-label="delete"
																					onClick={() => {
																						const fieldValues =
																							values[
																								`${value[0]}_array`
																							];
																						const idx =
																							fieldValues.findIndex(
																								(
																									v: string
																								) =>
																									v ==
																									item
																							);
																						const newValues =
																							[
																								...fieldValues,
																							];
																						newValues.splice(
																							idx,
																							1
																						);
																						setFieldValue(
																							`${value[0]}_array`,
																							newValues
																						);
																						setFieldValue(
																							`${value[0]}`,
																							""
																						);
																					}}
																				>
																					<DoNotDisturbOnIcon color="primary" />
																				</IconButton>
																			}
																		>
																			<ListItemText
																				primary={
																					typeof item ==
																					"string"
																						? item
																						: item.name
																				}
																			/>
																		</ListItem>
																	)
																)}
														</List>
													</>
												)}
											</Box>
											<If
												condition={
													conflictingFields[value[0]]
												}
											>
												<Alert severity="warning">
													There were multiple values
													for this field
												</Alert>
											</If>
										</Box>
									)
								)}
							</Box>
						</form>
					);
				}}
			</Formik>
		</PricingModal>
	);
}
