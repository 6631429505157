import React, { useMemo } from "react";
import { convertToMonthYearFormat, OverviewCardprops, style } from "./common";
import { Box, Grid, Typography } from "@mui/material";
import { PrimaryButton } from "../../../../common/components/CustomButton";
import { If } from "../../../../common/components/If";
import { PricingStepperStep } from "../../hooks/usePricingStepper";
import { ModeEdit } from "@mui/icons-material";
import { FinancialType } from "../../PricingRequestStepper.constant";

export interface FinancialOverviewCardProps extends OverviewCardprops {}

export function FinancialOverviewCard(props: FinancialOverviewCardProps) {
	const isFixedPricing = () => {
		return (
			props.pricingValues.strikePricingType?.name ===
			FinancialType.FIXED_FINANCIAL
		);
	};

	const isDAPricing = () => {
		return (
			props.pricingValues.strikePricingType?.name ===
			FinancialType.DA_FINANCIAL
		);
	};

	const isClickPricing = () => {
		return (
			props.pricingValues.strikePricingType?.name ===
			FinancialType.CLICK_FINANCIAL
		);
	};

	const granularities = useMemo(() => {
		const all = [];
		if (props.pricingValues.granularityMonthly) {
			all.push("Monthly");
		}
		if (props.pricingValues.granularityCal) {
			all.push("CAL");
		}
		if (props.pricingValues.granularityQuarter) {
			all.push("Quarter");
		}
		if (props.pricingValues.granularityWholeHorizon) {
			all.push("Whole Horizon");
		}
		return all.join(", ");
	}, [props.pricingValues]);

	return (
		<Grid item xs={6} sx={style.overviewGridItem}>
			<Box sx={style.overviewBox}>
				<Typography
					variant="h3"
					component="h2"
					sx={style.overviewBoxTitle}
				>
					Financial pricing
				</Typography>
				<Typography variant="h3" component="h2">
					Strike price:
				</Typography>
				<Typography component="p">
					&#x2022;&nbsp;Fixed: {isFixedPricing() ? "Yes" : "No"}
				</Typography>
				<Typography component="p">
					&#x2022;&nbsp;DA: {isDAPricing() ? "Yes" : "No"}
				</Typography>
				<Typography component="p">
					&#x2022;&nbsp;Click: {isClickPricing() ? "Yes" : "No"}
				</Typography>

				<Typography component="p">
					Period:&nbsp;
					{convertToMonthYearFormat(
						props.pricingValues.startDate
					)}{" "}
					-&nbsp;
					{convertToMonthYearFormat(props.pricingValues.endDate)}
				</Typography>
				<Typography component="p">
					<Typography component="span">{granularities}</Typography>
				</Typography>
				{props.pricingValues.baseOptionSwitch &&
					props.pricingValues.baseCapacity && (
						<Typography component="p">
							Base Capacity Volume:&nbsp;
							{props.pricingValues.baseCapacity}
						</Typography>
					)}

				<Typography component="p">
					Clickability Options:&nbsp;
					{props.pricingValues.clickOptionSwitch ? "Yes" : "No"}
				</Typography>
				{props.pricingValues.clickOptionSwitch && (
					<Typography component="p">
						Granularity:
						<Typography component="ul">
							{props.pricingValues.clickGranularity?.map(
								(granularity) => (
									<>
										<If
											condition={granularity == "NoSplit"}
											key={granularity}
										>
											<li>NoSplit</li>
										</If>
										<If
											condition={granularity != "NoSplit"}
											key={granularity}
										>
											<li>
												{granularity} with number
												of&nbsp; clicks: &nbsp;
												{Number(
													props.pricingValues[
														`clickCount${granularity}`
													]
												)}
											</li>
										</If>
									</>
								)
							)}
						</Typography>
					</Typography>
				)}
				<PrimaryButton
					text="Edit"
					type="button"
					color="secondary"
					sx={{
						...style.overViewFloatingButton,
						width: 160,
					}}
					onClick={() => props.setStep(PricingStepperStep.FINANCIAL)}
				>
					<ModeEdit sx={{ color: "#334551" }} />
				</PrimaryButton>
			</Box>
		</Grid>
	);
}
