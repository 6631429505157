import { Box } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import { DateRangePicker, InputGroup, InputNumber } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { predefinedRanges } from "./dateranges";
import CheckboxesTags, {
	SPECIAL_TAG_ALL_ID,
} from "../../common/components/CheckboxesTags";
import { DateRange } from "rsuite/esm/DateRangePicker/types";
import CustomInput from "../../common/components/CustomInput";
import { Filter, FilterType, PickListItem } from "../metadata/metadata.module";
import { useCallback, useMemo } from "react";

interface FilterRowInputProps {
	filter: Filter;
}

export default function FilterRow(props: FilterRowInputProps) {
	const { filter } = props;
	const { handleChange, setFieldValue, values, errors } = useFormikContext();

	const normalOptions = useMemo(
		() =>
			[...((filter.pickList || []) as PickListItem[])]?.sort(
				(a: PickListItem, b: PickListItem) =>
					a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
			),
		[filter]
	);

	const options = useMemo(() => {
		const sortedFilters = [...normalOptions];
		sortedFilters.unshift({ name: "Select All", id: SPECIAL_TAG_ALL_ID });
		return sortedFilters;
	}, [normalOptions, values, filter]);

	const onListChange = useCallback(
		(field: string, value: any, shouldValidate?: boolean | undefined) => {
			if (
				value
					.map((option: any) => option.id)
					.includes(SPECIAL_TAG_ALL_ID)
			) {
				if (value.length <= normalOptions.length) {
					// select all clicked when not every options are selected
					setFieldValue(field, normalOptions, shouldValidate);
				} else {
					// select all clicked when every options are already selected
					setFieldValue(field, [], shouldValidate);
				}
			} else {
				setFieldValue(field, value, shouldValidate);
			}
		},
		[setFieldValue, normalOptions]
	);
	return (
		<Box sx={{ flexDirection: "row", paddingTop: "3px" }}>
			{filter.filter_type === FilterType.alphanumeric ||
				(filter.filter_type === FilterType.list_input && (
					<CustomInput
						data-testid={"filter-input-" + filter.field_key}
						id={filter.field_key}
						aria-label={`search-${filter.field_key}`}
						value={getIn(values, filter.field_key)}
						onChange={handleChange}
						placeholder={filter.display_name}
						pattern={filter.pattern}
						patternTitle={filter.pattern_title}
					></CustomInput>
				))}

			{(filter.filter_type === FilterType.list ||
				filter.filter_type === FilterType.list_not_in) && (
				<CheckboxesTags<{ id: string | string[]; name: string }>
					data-testid={"filter-input-" + filter.field_key}
					label=""
					trackBy="id"
					name={filter.field_key}
					value={getIn(values, filter.field_key) ?? []}
					options={options}
					setFieldValue={onListChange}
				/>
			)}
			{filter.filter_type === FilterType.date && (
				<DateRangePicker
					data-testid={"filter-input-" + filter.field_key}
					// @ts-ignore
					ranges={predefinedRanges}
					value={getIn(values, filter.field_key)}
					size="lg"
					placement="auto"
					onChange={(value: DateRange | null) => {
						setFieldValue(filter.field_key, value);
					}}
				/>
			)}
			{filter.filter_type === FilterType.numeric && (
				<>
					<InputGroup>
						<InputNumber
							data-testid={
								"filter-input-" + filter.field_key + "-min"
							}
							min={0}
							value={(getIn(values, filter.field_key) ?? [])[0]}
							onChange={(nextValue) => {
								const val = getIn(values, filter.field_key);
								val[0] = nextValue;
								setFieldValue(filter.field_key, val);
							}}
						/>
						<InputGroup.Addon>to</InputGroup.Addon>
						<InputNumber
							data-testid={
								"filter-input-" + filter.field_key + "-max"
							}
							min={0}
							value={(getIn(values, filter.field_key) ?? [])[1]}
							onChange={(nextValue) => {
								const val = getIn(values, filter.field_key);
								val[1] = nextValue;
								setFieldValue(filter.field_key, val);
							}}
						/>
					</InputGroup>
					{getIn(errors, filter.field_key) && (
						<p style={{ color: "red" }}>
							{getIn(errors, filter.field_key)}
						</p>
					)}
				</>
			)}
		</Box>
	);
}
