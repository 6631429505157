export const CLICK_GRANULARITY = ["Year", "Quarter", "Month", "NoSplit"];
export const PRICING_GRANULARITY = [
	"monthly",
	"quarter",
	"CAL",
	"Whole Horizon",
];
export const CHAPTER51_TYPES = ["PayZero", "PayMarktwerte", "PayM0"];
export const CHAPTER51_NUMBER_OF_HOURS = [1, 2, 4, 6];
export const MAXIMUM_SITES_IN_ONE_GO = 100;
export const MAXIMUM_YEARS_IN_ONE_GO = 10;
export const MAXIMUM_TYPES_IN_ONE_GO = 10;

export const STRIKE_AVERAGE_TYPE = [
	{ key: "Index", value: "Index", label: "Monthly Average Price" },
	// {
	// 	key: "DailyWeighted",
	// 	value: "DailyWeighted",
	// 	label: "Daily Average Price",
	// },
	// { key: "Weighted", value: "Weighted", label: "Hourly Average Price" },
];

export const LEG_TYPE = [
	{ key: "AsProduced", value: "AsProduced", label: "As Produced" },
	{ key: "AsForecasted", value: "AsForecasted", label: "As Forecasted" },
];

export enum FinancialType {
	DA_FINANCIAL = "DA Financial",
	CLICK_FINANCIAL = "Click Financial",
	FIXED_FINANCIAL = "Fixed Financial",
}
