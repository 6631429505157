import {
	TableCell,
	TableRow,
	SxProps,
	Theme,
	CircularProgress,
} from "@mui/material";
import { memo, useState } from "react";
import AlertDialogModal from "../../../common/components/AlertDialogModal";
import { Column } from "../../../common/components/ColumnsSelector/ColumnsSelector";
import { HamburgerMenu } from "../../../common/components/HamburgerMenu";
import { HamburgerOptions } from "../../../common/components/HamburgerOptions";
import { secondaryColor } from "../../../core/theme";
import { PricingTableHeaderCell } from "../../pricing_list/components/PricingTable/PricingTableHeaderCell";
import { ColumnIds } from "../../pricing_list/hooks/columns/common";
import { usePricingTableRowModel } from "../../pricing_list/hooks/columns/usePricingTableRowModel";
import { Action } from "../../pricing_list/pricingListSlice";
import { useSelector } from "react-redux";
import { selectStatusUpdateIsPending } from "../../grouping/grouping.selector";

const style: { [key: string]: SxProps<Theme> } = {
	stickyHeader: {
		position: "sticky",
		left: 0,
		background: secondaryColor,
		zIndex: 1000,
		paddingLeft: "5px !important",
		"& > th": { padding: "0px", border: 0 },
		border: 0,
	},
};

interface PricingTableHeaderProps {
	availableActionsForSelection: Action[];
	onAction: (action: Action) => void;
	clearPricingSelection: () => void;
	selectAll: () => void;
	selectedColumns?: Column<ColumnIds>[];
	onDownloadRunToExcel: () => void;
	onPricingsDownload: () => void;
}

function PricingTableHeaderComponent(props: PricingTableHeaderProps) {
	const {
		clearPricingSelection,
		availableActionsForSelection,
		selectAll,
		onAction,
		onDownloadRunToExcel,
		selectedColumns,
		onPricingsDownload,
	} = props;

	const rowModel = usePricingTableRowModel(selectedColumns);

	const [selectedAction, setSelectedAction] = useState<Action | undefined>(
		undefined
	);
	const isLoading = useSelector(selectStatusUpdateIsPending);

	return (
		<>
			<TableRow
				sx={{
					"&:td, &:last-child th": {
						border: 0,
					},
					"& > th": { padding: "0px" },
					border: 0,
				}}
			>
				<TableCell sx={style.stickyHeader}>
					<PricingTableHeaderCell size={40}>
						{isLoading ? (
							<CircularProgress size={20} />
						) : (
							<HamburgerMenu datatestId="hamburgerMenuList">
								{availableActionsForSelection.map((action) => (
									<HamburgerOptions
										key={action.action}
										title={action.display_name}
										action={() => {
											return !!Object.keys(
												action.confirmation_modal ?? {}
											).length
												? setSelectedAction(action)
												: onAction(action);
										}}
									/>
								))}
								<HamburgerOptions
									title={"Export pricings"}
									action={onPricingsDownload}
								/>
								<HamburgerOptions
									title={"Export pricing runs"}
									action={onDownloadRunToExcel}
								/>
								<HamburgerOptions
									title={"Select All"}
									action={() => selectAll()}
								/>
								<HamburgerOptions
									title={"Clear Selection"}
									action={() => clearPricingSelection()}
								/>
							</HamburgerMenu>
						)}
						<AlertDialogModal
							title={
								selectedAction?.confirmation_modal?.title ?? ""
							}
							content={
								selectedAction?.confirmation_modal?.content
							}
							isOpen={!!selectedAction}
							handleClose={() => setSelectedAction(undefined)}
							handleApprove={() => {
								onAction(selectedAction as Action);
								setSelectedAction(undefined);
							}}
						/>
					</PricingTableHeaderCell>
					<PricingTableHeaderCell size={35} sx={{ zIndex: -1 }} />
					{rowModel.stickyColumns.map((column) => (
						<PricingTableHeaderCell
							key={column.columnId}
							size={column.width}
						>
							{column.renderHeader()}
						</PricingTableHeaderCell>
					))}
				</TableCell>
				{rowModel.nonStickyColumns.map((column) => (
					<PricingTableHeaderCell
						key={column.columnId}
						size={column.width}
					>
						{column.renderHeader()}
					</PricingTableHeaderCell>
				))}
				<PricingTableHeaderCell sx={{ alignSelf: "right" }} size={70}>
					Comment
				</PricingTableHeaderCell>

				<PricingTableHeaderCell size={40}></PricingTableHeaderCell>
			</TableRow>
		</>
	);
}

export const PricingTableHeader = memo(PricingTableHeaderComponent);
