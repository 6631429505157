import { Column } from "../../../../common/components/ColumnsSelector/ColumnsSelector";

export enum ColumnIds {
	PGID = "pgid",
	PID = "pid",
	PRID = "prid",
	TOURBILLON_REF = "tourbillon_ref",
	PR_STATUS = "pr_status",
	PR_SITE = "pr_site",
	PR_ALPHA_OR_BETA = "pr_alpha_or_beta",
	P_TYPE_NAME = "p_type_name",
	P_TECHNO_NAME = "p_techno_name",
	PRICING_DATE = "pricing_date",
	P_VALIDITY_DATE = "p_validity_date",
	P_PARTY = "p_party",
	P_BASE_CAPA = "p_base_capa",
	CLICKABILITY = "clickability",
	DELIVERY_PERIOD = "delivery_period",
	P_INSTALL_CAPA = "p_install_capa",
	P_CHAPTER_51 = "p_chapter_51",
	CUSTOMER_P50 = "customer_p50",
	P50 = "p50",
	PROXY = "proxy",
	LF = "lf",
	QTY = "qty",
	CROSS = "cross",
	GOO_CROSS = "goo_cross",
	GOO_ADJ = "goo_adj",
	XBC = "xbc",
	XPC = "xpc",
	XDPC = "xdpc",
	P50_PROV = "p50_prov",
	MARGIN_PROV = "margin_prov",
	GOO_PRICE = "goo_price",

	MARGIN_PROVISION = "margin_provision",
	ALPHA_PROVISION = "alpha_provision",
	BETA_PROVISION = "beta_provision",
	ALPHA_AND_BETA_PROVISION = "alpha_and_beta_provision",

	ALPHA = "alpha",
	PR_BETA = "pr_beta",

	ALPHAINDEX_AND_BETA = "ALPHAINDEX_AND_BETA",
	DELTA_ALPHAINDEX = "DELTA_ALPHAINDEX",
	DELTA_BETA = "DELTA_BETA",
	DELTA_ALPHAINDEX_AND_BETA = "DELTA_ALPHAINDEX_AND_BETA",

	INDIC = "indic",
	PR_CM = "pr_cm",
	PR_ALPHACLIENT = "pr_alphaclient",
	PR_INDICCLIENT = "pr_indicclient",
	PR_FIXING_DT = "pr_fixing_dt",
	PR_BASELOAD_PRICE = "pr_baseload_price",
	PR_BASELOAD_MID = "pr_baseload_mid",
	PR_COMMERCIAL_MARGIN = "pr_commercial_margin",
	PR_FIXPRICE = "pr_fixprice",
	PR_BETAFIX = "pr_betafix",
	PR_CONTRACT_ID = "pr_contract_id",
	CLEANING_WARNING = "cleaning_warning",
	CLICKABILITY_GRANULARITY = "clickability_granularity",
	SHAPE = "shape",
	SETUPFEE = "SetupFee",
	HEDGERATIOPERCENT = "hedge_ration_percent",
	IMBALANCECOSTPROVISION = "ImbalanceCostProvision",
	PROFILECOSTPROVISION = "ProfileCostProvision",
	DELTAPROFILECOSTPROVISION = "DeltaProfileCostProvision",
	SHAPEPROVISION = "ShapeProvision",
	SHAPEBIDASKPROVISION = "ShapeBidAskProvision",
	FIXINGGOOCOMMERCIALMARGIN = "FixingGooCommercialMargin",
	FINALGOOPRICE = "FinalGooPrice",
	NEGATIVEPRICEVALUE = "NegativePriceValue",
	BALANCING_GROUP = "BalancingGroup",
	TOTALPOWERMARGIN = "TotalPowerMargin",
	TOTALGOOMARGIN = "TotalGOOMargin",
	ALPHAINDEX = "AlphaIndex",
	GOO_ADJ_INPUT = "goo_adj_input",
	LIVE_PRICING_WAS_USED = "live_pricing_used",
}

export interface ColumnConfigPreference {
	version: number;
	data: Column<ColumnIds>[];
}
