import React, { useMemo, useState } from "react";
import {
	Pricing,
	PricingRun,
	PricingRunSummaryMetric,
	PricingStatus,
} from "../../pricingListSlice";
import {
	Box,
	Button,
	Fade,
	IconButton,
	Menu,
	MenuItem,
	SxProps,
	Theme,
} from "@mui/material";
import CountdownTimer from "../../../../common/components/CountdownTimer/CountdownTimer";
import { If } from "../../../../common/components/If";
import { PricingStatusComp } from "../../../../common/components/StatusIcon";
import { siteNameOrPortfolio } from "../../../../common/utils/siteNameOrPortfolio";
import { grey } from "@mui/material/colors";
import { SiteLogoOrPortfolioLogo } from "../../../../common/components/SiteOrPortfolioLogo";
import { lightGrey, primaryColor, white } from "../../../../core/theme";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { formatPercentage } from "../../../../common/utils/formatPercentage";
import { formatDecimal } from "../../../../common/utils/formatDecimal";
import { formatMWH } from "../../../../common/utils/formatMWH";
import { formatInteger } from "../../../../common/utils/formatInteger";
import { formatCurrency } from "../../../../common/utils/formatCurrency";
import { formatDateDDMMYYYYCET } from "../../../../common/utils/dateUtils";
import { PATH, getPathWithParameters } from "../../../../router-path";
import { Link } from "react-router-dom";
import { Column } from "../../../../common/components/ColumnsSelector/ColumnsSelector";
import * as _ from "lodash";
import { ColumnIds } from "./common";
import CommentIcon from "@mui/icons-material/Comment";
import CommentsDisabledIcon from "@mui/icons-material/CommentsDisabled";
import { notNullLike } from "../../../../common/utils/notNullLike";
import PricingTableRowBox from "../../components/PricingTable/PricingTableRowBox";
import { GenericTableRowTooltip } from "../../../../common/components/GenericTableRowTooltip";
import { dispatch } from "@engie-group/fluid-design-system-react";
import { setPersistedFilters } from "../../../filters/filtersSlice";
import { selectPersistedFilters } from "../../../filters/filters.selector";
import { useSelector } from "react-redux";
import { ClusterBreakdown } from "../../components/ClusterBreakdown";
import { formatYesNoMaybe } from "../../../../common/utils/formatYesNoMaybe";

const style: { [key: string]: SxProps<Theme> } = {
	container: {
		minWidth: "1280px",
		bgcolor: lightGrey,
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		height: "100%",
		overflow: "hidden",
		marginBottom: "30px",
	},
	titleContainer: {
		display: "flex",
		height: 80,
		width: "100%",
		alignItems: "center",
	},
	title: {
		marginBottom: "30px",
		flexGrow: "2",
		fontWeight: 400,
		fontSize: "36px",
		margin: 0,
		display: "flex",
		alignItems: "center",
	},
	tableWrapper: {
		marginTop: "30px",
		maxWidth: "100%",
		maxHeight: "100%",
		border: `1px solid ${grey}`,
		overflow: "scroll",
	},
	table: {
		minWidth: 650,
	},
	siteOrPortfolioContainer: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
	},

	stickyRow: {
		position: "sticky",
		left: 0,
		paddingLeft: "5px !important",
		paddingTop: "10px !important",
		paddingBottom: "10px !important",
		background: white,
		"& > td": { padding: "0px" },
	},
	wayPoint: {
		height: 10,
	},
	warningIcon: {
		color: "orange",
		fontSize: 16,
	},
};

export interface PricingCellProps {
	openGraphModal: (pricing: Pricing | undefined) => void;
	pricing?: Pricing;
	valueOrPercent?: "value" | "percent";
	pricingRun?: PricingRun;
	statusIsLoading?: boolean;
	isScrolling?: boolean;
	openWarningModal: (message: string) => void;
	getValueOrPercent: (
		metric: PricingRunSummaryMetric | undefined,
		valueFormatter: (value: number | null | undefined) => string
	) => any;
}

export interface PricingTableColumn {
	// used for key when mapping with react andas a technical id when interacting
	// with the column selector.
	columnId: ColumnIds;
	// width in pixel of this column, enforced by parent
	width: number;
	// return a unique string id by cell, used for live update
	cellIdFactory: (pricing?: Pricing, pricingRun?: PricingRun) => string;
	// return a primitive value to check if the content changed with ===
	fieldValueExtractor: (props: PricingCellProps) => any;
	// renders a component in the cell
	renderCell: (props: PricingCellProps) => React.ReactElement;
	// renders a component in the header
	renderHeader: () => React.ReactElement;
}

export interface PricingTableConfiguration {
	stickyColumns: PricingTableColumn[];
	nonStickyColumns: PricingTableColumn[];
}

const getPricingDate = (
	pricingParam: Pricing | undefined,
	pricingRunParam: PricingRun | undefined
) => {
	if (pricingParam && pricingParam?.is_real_time) {
		return "Live";
	} else if (
		pricingParam &&
		pricingParam?.pricing_runs?.[0]?.pricing_run_date
	) {
		return pricingParam.pricing_runs?.[0]?.pricing_run_date;
	} else if (pricingRunParam) {
		return pricingRunParam.pricing_run_date;
	}
	return undefined;
};

export function simpleColumn(
	width: number,
	title: string,
	columnId: ColumnIds,
	value: (props: PricingCellProps) => any
): PricingTableColumn {
	/**
	 * Most columns' cells and header are really simple and don't really need
	 * all the props and complexity of the full PricingTableConfiguration, this helpers
	 * generate the full object from a minimal subset of properties
	 */
	return {
		width,
		columnId,
		cellIdFactory: (pricing) => "" + pricing?.id + columnId,
		fieldValueExtractor: value,
		renderCell: (props) => <>{value(props)}</>,
		renderHeader: () => <>{title}</>,
	};
}

function isBaseprod(pricing?: Pricing) {
	return (
		pricing?.pricing_type?.geco_name ||
		pricing?.pricing_type?.long_name ||
		pricing?.pricing_type?.name
	)
		?.toLowerCase()
		.includes("baseprod");
}
const columns: PricingTableConfiguration = {
	stickyColumns: [
		simpleColumn(
			45,
			"GR_ID",
			ColumnIds.PGID,
			({ pricing }) => pricing?.pricing_group_id
		),
		{
			columnId: ColumnIds.PID,
			width: 40,
			cellIdFactory: (pricing) => pricing?.id.toString() || "",
			fieldValueExtractor: ({ pricing }) => pricing?.id,
			renderCell: (props) => {
				const persistedFilters = useSelector(selectPersistedFilters);
				const pricingIdParams = {
					p_id: props.pricing?.id.toString(),
				};
				const params = new URLSearchParams();
				params.set("filters", JSON.stringify(pricingIdParams));
				return (
					<Link
						to={{
							pathname: getPathWithParameters(
								PATH.TENDER_PRICING_RUN,
								{
									tenderId: props.pricing?.tender_id,
								}
							),
							search: "?" + params.toString(),
						}}
						onClick={() => {
							dispatch(
								setPersistedFilters({
									...persistedFilters.filters,
									pricings_filters_count: "1",
								})
							);
						}}
					>
						{props.pricing?.id}
					</Link>
				);
			},
			renderHeader: () => <>P_ID</>,
		},
		simpleColumn(
			45,
			"PR_ID",
			ColumnIds.PRID,
			({ pricingRun }) => pricingRun?.id
		),
		{
			columnId: ColumnIds.TOURBILLON_REF,
			width: 110,
			cellIdFactory: (pricing) => "" + pricing?.id + "tourbillon_ref",
			fieldValueExtractor: ({ pricing }) => pricing?.tourbillon_ref,
			renderCell: (props) => {
				if (props.pricing?.portfolio_tourbillon_ref_breakdown) {
					return (
						<ClusterBreakdown
							breakdown={
								props.pricing
									?.portfolio_tourbillon_ref_breakdown
							}
						/>
					);
				} else {
					return (
						<PricingTableRowBox
							sx={{
								border: "0 !important",
								minWidth: 110,
								maxWidth: 110,
								width: 110,
							}}
							value={
								props.pricing?.tourbillon_ref
									? props.pricing?.tourbillon_ref
									: "CLUSTER"
							}
						/>
					);
				}
			},
			renderHeader: () => <>Proxy</>,
		},
		{
			columnId: ColumnIds.PR_STATUS,
			width: 120,
			cellIdFactory: (pricing) => "" + pricing?.id + "pr_status",
			fieldValueExtractor: ({ pricingRun }) => pricingRun?.status,
			renderCell: ({
				pricing,
				isScrolling,
				statusIsLoading,
				pricingRun,
			}) => (
				<If condition={pricing}>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							color: primaryColor,
						}}
					>
						<PricingStatusComp
							isScrolling={isScrolling}
							loader={statusIsLoading}
							pricingRun={pricingRun}
							width="100px"
						/>
						{pricing?.price_max_validity &&
							["FIXED", "BETA_FIXED"].includes(
								pricingRun?.status || ""
							) && (
								<CountdownTimer
									targetDate={
										new Date(
											pricing?.price_max_validity.endsWith(
												"Z"
											)
												? pricing?.price_max_validity
												: `${pricing?.price_max_validity}Z`
										)
									}
									size={24}
									sx={{ marginLeft: "-50px" }}
								/>
							)}
					</Box>
				</If>
			),
			renderHeader: () => <>Status</>,
		},
		{
			columnId: ColumnIds.PR_SITE,
			width: 180,
			cellIdFactory: (pricing) => "" + pricing?.id + "pr_site",
			fieldValueExtractor: ({ pricing }) => siteNameOrPortfolio(pricing),
			renderCell: ({ pricing, isScrolling, openGraphModal }) => (
				<Box sx={style.siteOrPortfolioContainer}>
					<If condition={pricing}>
						<If condition={!isScrolling}>
							<SiteLogoOrPortfolioLogo pricing={pricing} />
							<IconButton
								aria-label="open-graph"
								sx={{ height: "24px" }}
							>
								<EqualizerIcon
									onClick={() => openGraphModal(pricing)}
								/>
							</IconButton>
						</If>
						<If condition={isScrolling}>
							<Box
								sx={{
									height: "24px",
									width: "20px",
									marginRight: "5px",
									borderRadius: "5px",
									backgroundColor: "rgb(87, 87, 87)",
									opacity: 0.8,
								}}
							/>
						</If>
						<GenericTableRowTooltip
							value={siteNameOrPortfolio(pricing)}
							sx={{
								alignSelf: "center",
								textOverflow: "ellipsis",
								overflow: "hidden",
								whiteSpace: "nowrap",
								width: "100%",
							}}
						/>
					</If>
				</Box>
			),
			renderHeader: () => <>Site / Port</>,
		},
		{
			columnId: ColumnIds.PR_ALPHA_OR_BETA,
			width: 80,
			cellIdFactory: (pricing) => "" + pricing?.id + "pr_alpha_or_beta",
			fieldValueExtractor: ({ pricing }) =>
				pricing?.alpha || pricing?.beta,
			renderCell: ({ pricing }) => {
				let resultOption = pricing?.selected_result_option || "";
				return (
					<GenericTableRowTooltip
						value={resultOption}
						sx={{
							alignSelf: "center",
							textOverflow: "ellipsis",
							overflow: "hidden",
							whiteSpace: "nowrap",
							width: "100%",
						}}
					/>
				);
			},
			renderHeader: () => <>Result Option</>,
		},
		{
			columnId: ColumnIds.P_TYPE_NAME,
			width: 185,
			cellIdFactory: (pricing) => "" + pricing?.id + "p_type_name",
			fieldValueExtractor: ({ pricing }) =>
				pricing?.pricing_type?.geco_name ||
				pricing?.pricing_type?.long_name ||
				pricing?.pricing_type?.name,
			renderCell: ({ pricing }) => (
				<PricingTableRowBox
					sx={{ width: 185 }}
					value={
						pricing?.pricing_type?.geco_name ||
						pricing?.pricing_type?.long_name ||
						pricing?.pricing_type?.name
					}
				/>
			),
			renderHeader: () => <>Pricing Type</>,
		},
		{
			columnId: ColumnIds.P_TECHNO_NAME,
			width: 80,
			cellIdFactory: (pricing) => "" + pricing?.id + "p_techno_name",
			fieldValueExtractor: ({ pricing }) => pricing?.techno?.name,
			renderCell: ({ pricing }) => (
				<PricingTableRowBox
					sx={{ width: 80 }}
					value={pricing?.techno?.name}
				/>
			),
			renderHeader: () => <>Technology</>,
		},
		{
			columnId: ColumnIds.PRICING_DATE,
			width: 85,
			cellIdFactory: (pricing) => "" + pricing?.id + "p_pricing_date",
			fieldValueExtractor: ({ pricing }) =>
				pricing?.pricing_runs?.[0]?.pricing_run_date,
			renderCell: ({ pricing, pricingRun }) => (
				<>{getPricingDate(pricing, pricingRun)}</>
			),
			renderHeader: () => <>Pricing Date</>,
		},
		simpleColumn(
			85,
			"Pricing Validity",
			ColumnIds.P_VALIDITY_DATE,
			({ pricing }) => pricing?.validity_date
		),
		{
			columnId: ColumnIds.P_PARTY,
			width: 150,
			cellIdFactory: (pricing) => "" + pricing?.id + "p_party",
			fieldValueExtractor: ({ pricing }) => pricing?.party_or_mirror,
			renderCell: ({ pricing }) => (
				<GenericTableRowTooltip
					value={pricing?.party_or_mirror}
					sx={{
						alignSelf: "center",
						textOverflow: "ellipsis",
						overflow: "hidden",
						whiteSpace: "nowrap",
						width: "100%",
					}}
				/>
			),
			renderHeader: () => <>Legal Entity / Book</>,
		},
		simpleColumn(50, "Base", ColumnIds.P_BASE_CAPA, ({ pricing }) =>
			isBaseprod(pricing)
				? formatPercentage(pricing?.base_capacity_volume_percentage)
				: "-"
		),
		{
			columnId: ColumnIds.CLICKABILITY,
			width: 50,
			cellIdFactory: (pricing) => "" + pricing?.id + "p_clickability",
			fieldValueExtractor: ({ pricing }) =>
				pricing?.clickability_params?.click_count,
			renderCell: ({ pricing }) => (
				<If
					condition={
						typeof pricing?.clickability_params?.click_count ===
						"number"
					}
				>
					{(pricing?.clickability_params?.click_count || -1) > 0
						? pricing?.clickability_params?.click_count
						: "No"}
				</If>
			),
			renderHeader: () => <>Click</>,
		},
		{
			columnId: ColumnIds.CLICKABILITY_GRANULARITY,
			width: 70,
			cellIdFactory: (pricing) =>
				"" + pricing?.id + "p_clickability_granularity",
			fieldValueExtractor: ({ pricing }) =>
				pricing?.clickability_params?.granularity,
			renderCell: ({ pricing }) => (
				<If condition={pricing?.clickability_params?.granularity}>
					{pricing?.clickability_params?.granularity}
				</If>
			),
			renderHeader: () => <>Click granularity</>,
		},
		{
			columnId: ColumnIds.DELIVERY_PERIOD,
			width: 70,
			cellIdFactory: (pricing) => "" + pricing?.id + "p_delivery_period",
			fieldValueExtractor: ({ pricing }) => pricing?.delivery_period,
			renderCell: ({ pricing }) => (
				<If condition={pricing}>{pricing?.delivery_period}</If>
			),
			renderHeader: () => <>Delivery</>,
		},
		{
			columnId: ColumnIds.P_INSTALL_CAPA,
			width: 70,
			cellIdFactory: (pricing) => "" + pricing?.id + "p_install_capa",
			fieldValueExtractor: ({ pricing }) =>
				pricing?.installed_capacity?.toFixed(2),
			renderCell: ({ pricing }) => (
				<>{formatDecimal(pricing?.installed_capacity)}</>
			),
			renderHeader: () => <>MW</>,
		},
		{
			columnId: ColumnIds.P_CHAPTER_51,
			width: 80,
			cellIdFactory: (pricing) => "" + pricing?.id + "p_chapter_51",
			fieldValueExtractor: ({ pricing }) => pricing?.chapter51_type,
			renderCell: ({ pricing }) => (
				<Box
					sx={{
						border: "0 !important",
						width: 80,
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
					}}
				>
					<If condition={pricing?.chapter51_type}>
						<GenericTableRowTooltip
							value={
								pricing?.chapter51_type +
								" " +
								pricing?.chapter51_number_of_hours
							}
						/>
					</If>
					<If condition={!pricing?.chapter51_type}>
						<GenericTableRowTooltip value="-" />
					</If>
				</Box>
			),
			renderHeader: () => <>§51</>,
		},
		{
			columnId: ColumnIds.NEGATIVEPRICEVALUE,
			width: 80,
			cellIdFactory: (pricing) => "" + pricing?.id + "negativeprice",
			fieldValueExtractor: ({ pricing }) => pricing?.negative_price_value,
			renderCell: ({ pricing }) => (
				<>
					<If
						condition={
							!!pricing?.negative_price_value ||
							pricing?.negative_price_value === 0
						}
					>
						{pricing?.negative_price_value}
					</If>
					<If
						condition={
							!pricing?.negative_price_value &&
							pricing?.negative_price_value !== 0
						}
					>
						-
					</If>
				</>
			),
			renderHeader: () => <>Negative Price</>,
		},
		{
			columnId: ColumnIds.BALANCING_GROUP,
			width: 80,
			cellIdFactory: (pricing) => "" + pricing?.id + "balancing_group",
			fieldValueExtractor: ({ pricing }) =>
				pricing?.balancing_group_label,
			renderCell: ({ pricing }) => <>{pricing?.balancing_group_label}</>,
			renderHeader: () => <>Balancing Group</>,
		},
	],
	nonStickyColumns: [
		{
			columnId: ColumnIds.CUSTOMER_P50,
			width: 90,
			cellIdFactory: (pricing) => "" + pricing?.id + "p_customer_p50",
			fieldValueExtractor: ({ pricing }) =>
				pricing?.site_pricings?.[0]?.site.real_power_production,
			renderCell: ({ pricing }) => (
				<>
					<If
						condition={
							pricing?.site_pricings &&
							pricing?.site_pricings.length > 1
						}
					>
						{pricing?.site_pricings.reduce(
							(t, s) => t + (s?.site.real_power_production ?? 0),
							0
						)}
					</If>

					<If
						condition={
							pricing?.site_pricings &&
							pricing?.site_pricings.length == 1
						}
					>
						{
							pricing?.site_pricings?.[0]?.site
								.real_power_production
						}
					</If>
				</>
			),
			renderHeader: () => <>Customer P50 (MWh)</>,
		},
		{
			columnId: ColumnIds.P50,
			width: 90,
			cellIdFactory: (pricing) => "" + pricing?.id + "p_p50",
			fieldValueExtractor: ({ pricingRun, getValueOrPercent }) =>
				getValueOrPercent(pricingRun?.summary?.P50, formatMWH),
			renderCell: ({ pricingRun, getValueOrPercent }) => (
				<>{getValueOrPercent(pricingRun?.summary?.P50, formatMWH)}</>
			),
			renderHeader: () => <>P50 (MWh)</>,
		},
		{
			columnId: ColumnIds.PROXY,
			width: 90,
			cellIdFactory: (pricing) => "" + pricing?.id + "p_proxy",
			fieldValueExtractor: ({ pricingRun, getValueOrPercent }) =>
				getValueOrPercent(pricingRun?.summary?.Proxy, formatMWH),
			renderCell: ({ pricingRun, getValueOrPercent }) => (
				<>{getValueOrPercent(pricingRun?.summary?.Proxy, formatMWH)}</>
			),
			renderHeader: () => <>Proxy (MWh)</>,
		},
		{
			columnId: ColumnIds.LF,
			width: 90,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_lf",
			fieldValueExtractor: ({ pricingRun }) => {
				const percent = pricingRun?.summary?.LF?.percent;
				if (typeof percent === "number") {
					return parseFloat((percent * 100).toFixed(2)) + "%";
				}
				return "-";
			},
			renderCell: ({ pricingRun }) => {
				const percent = pricingRun?.summary?.LF?.percent;
				if (typeof percent === "number") {
					const roundedPercent = (percent * 100).toFixed(2) + "%";
					return <>{roundedPercent}</>;
				}
				return <>-</>;
			},
			renderHeader: () => <>LF</>,
		},
		{
			columnId: ColumnIds.QTY,
			width: 90,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_qty",
			fieldValueExtractor: ({ pricingRun, getValueOrPercent }) =>
				getValueOrPercent(pricingRun?.summary?.Quantity, formatInteger),
			renderCell: ({ pricingRun, getValueOrPercent }) => (
				<>
					{getValueOrPercent(
						pricingRun?.summary?.Quantity,
						formatInteger
					)}
				</>
			),
			renderHeader: () => <>QTY (MWh)</>,
		},
		{
			columnId: ColumnIds.CROSS,
			width: 60,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_cross",
			fieldValueExtractor: ({ pricingRun, getValueOrPercent }) =>
				getValueOrPercent(pricingRun?.summary?.Cross, formatCurrency),
			renderCell: ({ pricingRun, getValueOrPercent }) => (
				<>
					{getValueOrPercent(
						pricingRun?.summary?.Cross,
						formatCurrency
					)}
				</>
			),
			renderHeader: () => <>Cross</>,
		},
		{
			columnId: ColumnIds.GOO_ADJ_INPUT,
			width: 60,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_goo_adj_input",
			fieldValueExtractor: ({ pricing }) =>
				formatPercentage(pricing?.goo_power_adjustment),
			renderCell: ({ pricing }) => (
				<>
					{pricing?.goo_power_adjustment === 0
						? "-"
						: formatPercentage(pricing?.goo_power_adjustment)}
				</>
			),
			renderHeader: () => <>GOO Adj. Input</>,
		},
		{
			columnId: ColumnIds.GOO_CROSS,
			width: 90,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_goo_cross",
			fieldValueExtractor: ({ pricingRun }) =>
				formatCurrency(pricingRun?.summary?.GOO_Cross?.value),
			renderCell: ({ pricingRun }) => (
				<>{formatCurrency(pricingRun?.summary?.GOO_Cross?.value)}</>
			),
			renderHeader: () => <>GOO_Cross</>,
		},
		{
			columnId: ColumnIds.XBC,
			width: 60,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_xbc",
			fieldValueExtractor: ({ pricingRun, getValueOrPercent }) =>
				getValueOrPercent(pricingRun?.summary?.XBC, formatCurrency),
			renderCell: ({ pricingRun, getValueOrPercent }) => (
				<>
					{getValueOrPercent(
						pricingRun?.summary?.XBC,
						formatCurrency
					)}
				</>
			),
			renderHeader: () => <>XBC</>,
		},
		{
			columnId: ColumnIds.XPC,
			width: 60,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_xpc",
			fieldValueExtractor: ({ pricingRun, getValueOrPercent }) =>
				getValueOrPercent(pricingRun?.summary?.XPC, formatCurrency),
			renderCell: ({ pricingRun, getValueOrPercent }) => (
				<>
					{getValueOrPercent(
						pricingRun?.summary?.XPC,
						formatCurrency
					)}
				</>
			),
			renderHeader: () => <>XPC</>,
		},
		{
			columnId: ColumnIds.XDPC,
			width: 60,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_xdpc",
			fieldValueExtractor: ({ pricingRun, getValueOrPercent }) =>
				getValueOrPercent(pricingRun?.summary?.XDPC, formatCurrency),
			renderCell: ({ pricingRun, getValueOrPercent }) => (
				<>
					{getValueOrPercent(
						pricingRun?.summary?.XDPC,
						formatCurrency
					)}
				</>
			),
			renderHeader: () => <>XDPC</>,
		},
		{
			columnId: ColumnIds.SHAPE,
			width: 100,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_shape",
			fieldValueExtractor: ({ pricingRun, getValueOrPercent }) =>
				getValueOrPercent(pricingRun?.summary?.Shape, formatCurrency),
			renderCell: ({ pricingRun, getValueOrPercent }) => (
				<>
					{getValueOrPercent(
						pricingRun?.summary?.Shape,
						formatCurrency
					)}
				</>
			),
			renderHeader: () => <>Shape</>,
		},
		{
			columnId: ColumnIds.P50_PROV,
			width: 100,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_p50_prov",
			fieldValueExtractor: ({ pricingRun, getValueOrPercent }) =>
				getValueOrPercent(
					pricingRun?.summary?.P50_PROV,
					formatCurrency
				),
			renderCell: ({ pricingRun, getValueOrPercent }) => (
				<>
					{getValueOrPercent(
						pricingRun?.summary?.P50_PROV,
						formatCurrency
					)}
				</>
			),
			renderHeader: () => <>P50_PROV</>,
		},
		{
			columnId: ColumnIds.MARGIN_PROV,
			width: 100,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_margin_prov",
			fieldValueExtractor: ({ pricingRun, getValueOrPercent }) =>
				getValueOrPercent(
					pricingRun?.summary?.Margin_PROV,
					formatCurrency
				),
			renderCell: ({ pricingRun, getValueOrPercent }) => (
				<>
					{getValueOrPercent(
						pricingRun?.summary?.Margin_PROV,
						formatCurrency
					)}
				</>
			),
			renderHeader: () => <>RES_Prov</>,
		},
		{
			columnId: ColumnIds.SETUPFEE,
			width: 100,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "_setupfee",
			fieldValueExtractor: ({ pricingRun, getValueOrPercent }) =>
				getValueOrPercent(
					pricingRun?.summary?.SetupFee,
					formatCurrency
				),
			renderCell: ({ pricingRun, getValueOrPercent }) => (
				<>
					{getValueOrPercent(
						pricingRun?.summary?.SetupFee,
						formatCurrency
					)}
				</>
			),
			renderHeader: () => <>Setup Fee</>,
		},
		{
			columnId: ColumnIds.IMBALANCECOSTPROVISION,
			width: 120,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "_imbalancecostprovision",
			fieldValueExtractor: ({ pricingRun, getValueOrPercent }) =>
				getValueOrPercent(
					pricingRun?.summary?.ImbalanceCostProvision,
					formatCurrency
				),
			renderCell: ({ pricingRun, getValueOrPercent }) => (
				<>
					{getValueOrPercent(
						pricingRun?.summary?.ImbalanceCostProvision,
						formatCurrency
					)}
				</>
			),
			renderHeader: () => <>Imbalance Cost Provision</>,
		},
		{
			columnId: ColumnIds.PROFILECOSTPROVISION,
			width: 120,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "_profilecostprovision",
			fieldValueExtractor: ({ pricingRun, getValueOrPercent }) =>
				getValueOrPercent(
					pricingRun?.summary?.ProfileCostProvision,
					formatCurrency
				),
			renderCell: ({ pricingRun, getValueOrPercent }) => (
				<>
					{getValueOrPercent(
						pricingRun?.summary?.ProfileCostProvision,
						formatCurrency
					)}
				</>
			),
			renderHeader: () => <>Profile Cost Provision</>,
		},
		{
			columnId: ColumnIds.DELTAPROFILECOSTPROVISION,
			width: 120,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "_deltaprofilecostprovision",
			fieldValueExtractor: ({ pricingRun, getValueOrPercent }) =>
				getValueOrPercent(
					pricingRun?.summary?.DeltaProfileCostProvision,
					formatCurrency
				),
			renderCell: ({ pricingRun, getValueOrPercent }) => (
				<>
					{getValueOrPercent(
						pricingRun?.summary?.DeltaProfileCostProvision,
						formatCurrency
					)}
				</>
			),
			renderHeader: () => <>Delta Profile Cost Provision</>,
		},
		{
			columnId: ColumnIds.SHAPEPROVISION,
			width: 100,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "_shapeprovision",
			fieldValueExtractor: ({ pricingRun, getValueOrPercent }) =>
				getValueOrPercent(
					pricingRun?.summary?.ShapeProvision,
					formatCurrency
				),
			renderCell: ({ pricingRun, getValueOrPercent }) => (
				<>
					{getValueOrPercent(
						pricingRun?.summary?.ShapeProvision,
						formatCurrency
					)}
				</>
			),
			renderHeader: () => <>Shape Provision</>,
		},
		{
			columnId: ColumnIds.SHAPEBIDASKPROVISION,
			width: 100,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "_shapebidaskprovision",
			fieldValueExtractor: ({ pricingRun, getValueOrPercent }) =>
				getValueOrPercent(
					pricingRun?.summary?.ShapeBidAskProvision,
					formatCurrency
				),
			renderCell: ({ pricingRun, getValueOrPercent }) => (
				<>
					{getValueOrPercent(
						pricingRun?.summary?.ShapeBidAskProvision,
						formatCurrency
					)}
				</>
			),
			renderHeader: () => <>Shape Bid Ask Provision</>,
		},
		{
			columnId: ColumnIds.GOO_ADJ,
			width: 90,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_goo_adj",
			fieldValueExtractor: ({ pricingRun, getValueOrPercent }) =>
				getValueOrPercent(pricingRun?.summary?.GOO_Adj, formatCurrency),
			renderCell: ({ pricingRun, getValueOrPercent }) => (
				<>
					{getValueOrPercent(
						pricingRun?.summary?.GOO_Adj,
						formatCurrency
					)}
				</>
			),
			renderHeader: () => <>GOO_Adj</>,
		},
		{
			columnId: ColumnIds.GOO_PRICE,
			width: 80,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_goo",
			fieldValueExtractor: ({ pricingRun }) =>
				pricingRun?.summary?.GOO_Price?.value
					? formatCurrency(pricingRun?.summary?.GOO_Price?.value)
					: formatCurrency(pricingRun?.summary?.GOO?.value),
			renderCell: ({ pricingRun }) => (
				<>
					{pricingRun?.summary?.GOO_Price?.value
						? formatCurrency(pricingRun?.summary?.GOO_Price?.value)
						: formatCurrency(pricingRun?.summary?.GOO?.value)}
				</>
			),
			renderHeader: () => <>GOO_Price</>,
		},
		{
			columnId: ColumnIds.MARGIN_PROVISION,
			width: 80,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_margin_provision",
			fieldValueExtractor: ({ pricingRun }) =>
				formatPercentage(pricingRun?.summary?.Provision?.value),
			renderCell: ({ pricingRun }) => (
				<>{formatPercentage(pricingRun?.summary?.Provision?.value)}</>
			),
			renderHeader: () => <>Provision</>,
		},
		{
			columnId: ColumnIds.ALPHA_PROVISION,
			width: 80,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_margin_provision",
			fieldValueExtractor: ({ pricingRun }) =>
				formatPercentage(pricingRun?.summary?.AlphaProvision?.value),
			renderCell: ({ pricing, pricingRun }) =>
				pricing?.alpha ? (
					<>
						{formatPercentage(
							pricingRun?.summary?.AlphaProvision?.value
						)}
					</>
				) : (
					<>{"-"}</>
				),
			renderHeader: () => <>Alpha Provision</>,
		},
		{
			columnId: ColumnIds.BETA_PROVISION,
			width: 80,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_margin_provision",
			fieldValueExtractor: ({ pricingRun }) =>
				formatCurrency(pricingRun?.summary?.BetaProvision?.value),
			renderCell: ({ pricing, pricingRun }) =>
				pricing?.beta ? (
					<>
						{formatCurrency(
							pricingRun?.summary?.BetaProvision?.value
						)}
					</>
				) : (
					<>{"-"}</>
				),
			renderHeader: () => <>Beta Provision</>,
		},
		{
			columnId: ColumnIds.ALPHA_AND_BETA_PROVISION,
			width: 80,
			cellIdFactory: () => "Alpha + beta Provision",
			fieldValueExtractor: ({ pricingRun }) =>
				formatPercentage(
					pricingRun?.summary?.AlphaProvisionForAlphaAndBeta?.value,
					2
				),
			renderCell: ({ pricing, pricingRun }) =>
				pricing?.alpha_and_beta &&
				(pricingRun?.summary?.AlphaProvisionForAlphaAndBeta?.value ||
					pricingRun?.summary?.BetaProvisionForAlphaAndBeta
						?.value) ? (
					<>
						alpha:&nbsp;
						{formatPercentage(
							pricingRun?.summary?.AlphaProvisionForAlphaAndBeta
								?.value
						)}{" "}
						<br />
						beta:&nbsp;
						{formatCurrency(
							pricingRun?.summary?.BetaProvisionForAlphaAndBeta
								?.value
						)}
					</>
				) : (
					<>{"-"}</>
				),
			renderHeader: () => <>Alpha + Beta Provision</>,
		},
		{
			columnId: ColumnIds.ALPHA,
			width: 80,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_alpha",
			fieldValueExtractor: ({ pricingRun }) =>
				formatPercentage(pricingRun?.summary?.Alpha?.value, 3),
			renderCell: ({ pricingRun }) => (
				<>{formatPercentage(pricingRun?.summary?.Alpha?.value, 3)}</>
			),
			renderHeader: () => <>Alpha baseload</>,
		},
		simpleColumn(
			100,
			"Alpha Index",
			ColumnIds.ALPHAINDEX,
			({ pricing, pricingRun }) =>
				!isBaseprod(pricing) && pricing?.alpha
					? formatPercentage(pricingRun?.summary?.AlphaIndex?.value)
					: "-"
		),
		simpleColumn(
			80,
			"Beta contract",
			ColumnIds.PR_BETA,
			({ pricing, pricingRun }) =>
				!isBaseprod(pricing) && pricing?.beta
					? formatCurrency(pricingRun?.summary?.BetaContract?.value)
					: "-"
		),
		{
			columnId: ColumnIds.ALPHAINDEX_AND_BETA,
			width: 80,
			cellIdFactory: () => "Alpha Index + beta",
			fieldValueExtractor: ({ pricingRun }) =>
				formatPercentage(
					pricingRun?.summary?.AlphaIndexAndBeta?.alpha.value,
					2
				),
			renderCell: ({ pricing, pricingRun }) =>
				!isBaseprod(pricing) && pricing?.alpha_and_beta ? (
					<>
						alpha:&nbsp;
						{formatPercentage(
							pricingRun?.summary?.AlphaIndexAndBeta?.alpha.value
						)}{" "}
						<br />
						beta:&nbsp;
						{formatCurrency(
							pricingRun?.summary?.AlphaIndexAndBeta?.beta.value
						)}
					</>
				) : (
					<>{"-"}</>
				),
			renderHeader: () => <>Alpha Index + beta</>,
		},
		simpleColumn(
			100,
			"Delta alpha index",
			ColumnIds.DELTA_ALPHAINDEX,
			({ pricing, pricingRun }) =>
				isBaseprod(pricing) && pricing?.alpha
					? formatPercentage(pricingRun?.summary?.AlphaIndex?.value)
					: "-"
		),
		simpleColumn(
			100,
			"Delta beta",
			ColumnIds.DELTA_BETA,
			({ pricing, pricingRun }) =>
				isBaseprod(pricing) && pricing?.beta
					? formatCurrency(pricingRun?.summary?.BetaContract?.value)
					: "-"
		),
		{
			columnId: ColumnIds.DELTA_ALPHAINDEX_AND_BETA,
			width: 80,
			cellIdFactory: () => "Delta alpha index + beta",
			fieldValueExtractor: ({ pricingRun }) =>
				formatPercentage(
					pricingRun?.summary?.AlphaIndexAndBeta?.alpha.value,
					2
				),
			renderCell: ({ pricing, pricingRun }) =>
				isBaseprod(pricing) && pricing?.alpha_and_beta ? (
					<>
						alpha:&nbsp;
						{formatPercentage(
							pricingRun?.summary?.AlphaIndexAndBeta?.alpha.value
						)}{" "}
						<br />
						beta:&nbsp;
						{formatCurrency(
							pricingRun?.summary?.AlphaIndexAndBeta?.beta.value
						)}
					</>
				) : (
					<>{"-"}</>
				),
			renderHeader: () => <>Delta alpha index + beta</>,
		},
		{
			columnId: ColumnIds.INDIC,
			width: 60,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_indic",
			fieldValueExtractor: ({ pricingRun }) =>
				formatCurrency(pricingRun?.summary?.INDIC?.value),
			renderCell: ({ pricingRun }) => (
				<>{formatCurrency(pricingRun?.summary?.INDIC?.value)}</>
			),
			renderHeader: () => <>Indic</>,
		},
		simpleColumn(80, "CM", ColumnIds.PR_CM, ({ pricingRun }) =>
			formatPercentage(pricingRun?.summary?.CM?.value)
		),
		simpleColumn(
			100,
			"Alpha Client",
			ColumnIds.PR_ALPHACLIENT,
			({ pricingRun }) =>
				formatPercentage(pricingRun?.summary?.AlphaClient?.value)
		),
		simpleColumn(
			100,
			"Indic Client",
			ColumnIds.PR_INDICCLIENT,
			({ pricingRun }) =>
				formatCurrency(pricingRun?.summary?.IndicClient?.value)
		),
		{
			columnId: ColumnIds.PR_FIXING_DT,
			width: 110,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_fixing_dt",
			fieldValueExtractor: ({ pricingRun }) =>
				formatDateDDMMYYYYCET(
					pricingRun?.summary?.FixingDateTime?.value
				),
			renderCell: ({ pricingRun }) => (
				<Box sx={{ fontSize: "13px", marginTop: "1px" }}>
					{formatDateDDMMYYYYCET(
						pricingRun?.summary?.FixingDateTime?.value
					)}
				</Box>
			),
			renderHeader: () => <>Fixing Date Time (CET)</>,
		},
		simpleColumn(
			100,
			"Baseload Price",
			ColumnIds.PR_BASELOAD_PRICE,
			({ pricingRun }) =>
				formatCurrency(pricingRun?.summary?.BaseloadPrice?.value)
		),
		simpleColumn(
			100,
			"Baseload Mid",
			ColumnIds.PR_BASELOAD_MID,
			({ pricingRun }) =>
				formatCurrency(pricingRun?.summary?.BaseloadMid?.value)
		),
		simpleColumn(
			80,
			"Fixing CM",
			ColumnIds.PR_COMMERCIAL_MARGIN,
			({ pricingRun }) => {
				if (
					pricingRun?.summary?.FixingCommercialMargin?.value ===
						null ||
					pricingRun?.summary?.FixingCommercialMargin?.value ===
						undefined
				) {
					return "-";
				}

				return (
					pricingRun?.summary?.FixingCommercialMargin?.value + " €"
				);
			}
		),
		simpleColumn(
			80,
			"Goo Commercial Margin",
			ColumnIds.FIXINGGOOCOMMERCIALMARGIN,
			({ pricingRun }) => {
				return formatCurrency(
					pricingRun?.summary?.FixingGooCommercialMargin?.value
				);
			}
		),
		simpleColumn(80, "Fix Price", ColumnIds.PR_FIXPRICE, ({ pricingRun }) =>
			formatCurrency(pricingRun?.summary?.FixPrice?.value)
		),
		simpleColumn(
			80,
			"Goo Final Price",
			ColumnIds.FINALGOOPRICE,
			({ pricingRun }) =>
				formatCurrency(pricingRun?.summary?.FinalGooPrice?.value)
		),

		simpleColumn(
			60,
			"Fix Beta",
			ColumnIds.PR_BETAFIX,
			({ pricing, pricingRun }) =>
				formatCurrency(
					notNullLike(
						pricing?.beta && pricingRun?.summary?.BetaFix?.value
					)
						? pricingRun?.summary?.BetaFix?.value
						: null
				)
		),
		simpleColumn(
			80,
			"Hedge Ratio",
			ColumnIds.HEDGERATIOPERCENT,
			({ pricingRun }) => {
				if (
					pricingRun?.summary?.HedgeRatioPercent?.value === null ||
					pricingRun?.summary?.HedgeRatioPercent?.value === undefined
				) {
					return "-";
				}

				return formatPercentage(
					pricingRun?.summary?.HedgeRatioPercent?.value
				);
			}
		),
		simpleColumn(
			80,
			"Total Power Margin",
			ColumnIds.TOTALPOWERMARGIN,
			({ pricingRun }) =>
				formatCurrency(pricingRun?.summary?.TotalPowerMargin?.value)
		),
		simpleColumn(
			80,
			"Total GOO Margin",
			ColumnIds.TOTALGOOMARGIN,
			({ pricingRun }) =>
				formatCurrency(pricingRun?.summary?.TotalGOOMargin?.value)
		),
		{
			columnId: ColumnIds.PR_CONTRACT_ID,
			width: 80,
			cellIdFactory: (_pricing, pricingRun) =>
				"" + pricingRun?.id + "pr_contract_id",
			fieldValueExtractor: ({ pricing }) => {
				if (pricing?.status === PricingStatus.BOOKED_MANUALLY) {
					return pricing?.geco_contract_id;
				}
				return pricing?.parties
					? pricing?.parties[0]?.geco_contract_id
					: null;
			},
			renderCell: ({ pricing }) => {
				const [contractMenu, setContractMenu] =
					useState<null | HTMLElement>(null);
				const open = Boolean(contractMenu);
				const handleClick = (event: React.MouseEvent<HTMLElement>) => {
					setContractMenu(event.currentTarget);
				};
				const handleClose = () => {
					setContractMenu(null);
				};

				return (
					<>
						<If
							condition={
								pricing?.parties &&
								pricing?.parties?.length === 1 &&
								pricing?.status === PricingStatus.BOOKED
							}
						>
							<Link
								style={{
									display: "flex",
									justifyContent: "center",
								}}
								to={
									pricing?.parties
										? getPathWithParameters(
												PATH.CONTRACT_DETAIL,
												{
													contractId:
														pricing?.parties[0]
															?.geco_contract_id,
												}
										  )
										: "#"
								}
							>
								{pricing?.parties
									? pricing?.parties[0]?.geco_contract_id
									: null}
							</Link>
						</If>
						<If
							condition={
								pricing?.parties &&
								pricing?.parties?.length === 1 &&
								pricing?.status ===
									PricingStatus.BOOKED_MANUALLY
							}
						>
							<Link
								style={{
									display: "flex",
									justifyContent: "center",
								}}
								to={
									pricing?.parties
										? getPathWithParameters(
												PATH.CONTRACT_DETAIL,
												{
													contractId:
														pricing?.geco_contract_id ??
														"",
												}
										  )
										: "#"
								}
							>
								{pricing?.geco_contract_id}
							</Link>
						</If>
						<If
							condition={
								pricing?.parties && pricing?.parties?.length > 1
							}
						>
							<Button
								id="contract-button"
								aria-controls={
									open ? "contract-menu" : undefined
								}
								aria-haspopup="true"
								aria-expanded={open ? "true" : undefined}
								onClick={handleClick}
							>
								Many
							</Button>
							<Menu
								id="contract-menu"
								MenuListProps={{
									"aria-labelledby": "contract-button",
								}}
								anchorEl={contractMenu}
								open={open}
								onClose={handleClose}
								TransitionComponent={Fade}
							>
								{pricing?.parties &&
									pricing?.parties.map((party) => (
										<MenuItem
											key={party.party_id}
											onClick={handleClose}
										>
											<Link
												to={getPathWithParameters(
													PATH.CONTRACT_DETAIL,
													{
														contractId:
															party?.geco_contract_id,
													}
												)}
											>
												{party?.geco_contract_id}
											</Link>
										</MenuItem>
									))}
							</Menu>
						</If>
					</>
				);
			},
			renderHeader: () => <>Contract Id</>,
		},
		simpleColumn(
			80,
			"Warning",
			ColumnIds.CLEANING_WARNING,
			(props: PricingCellProps) => (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<If condition={props.pricing?.timeserie?.warning}>
						<IconButton
							aria-label="comment"
							onClick={() =>
								props.openWarningModal(
									props.pricing?.timeserie?.warning || "error"
								)
							}
						>
							<CommentIcon sx={style.warningIcon} />
						</IconButton>
					</If>
					<If condition={!props.pricing?.timeserie?.warning}>
						<CommentsDisabledIcon sx={style.warningIcon} />
					</If>
				</Box>
			)
		),
		simpleColumn(
			80,
			"Live Pricing Used",
			ColumnIds.LIVE_PRICING_WAS_USED,
			({ pricingRun }) =>
				formatYesNoMaybe(pricingRun?.summary?.LivePricingWasUsed)
		),
	],
};

export function usePricingTableRowModel(
	selectedColumns: Column<ColumnIds>[] = []
): PricingTableConfiguration {
	const filtered = useMemo(
		() => ({
			stickyColumns: columns.stickyColumns.filter(
				(column) =>
					!!_.find(
						selectedColumns,
						(selected) => selected.id === column.columnId
					)
			),
			nonStickyColumns: columns.nonStickyColumns.filter(
				(column) =>
					!!_.find(
						selectedColumns,
						(selected) => selected.id === column.columnId
					)
			),
		}),
		[selectedColumns]
	);
	return filtered;
}
